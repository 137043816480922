html {
  margin: 0;
  padding: 0;
  min-width: 90vw;
  min-height: 100vh;
}

body {
  margin: 0;
  padding: 0;
  min-width: 90vw;
  min-height: 100vh;
}

#root {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  height: 100%;
  width: 100%;
}

.App {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

.sideBar {
  padding: 1em;
}

#mainColumnStack {
  min-height: 100vh;
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.spinner {
  font-family: "Segoe UI", "Segoe UI Web (West European)", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.react-monaco-editor-container {
  height: 100%;
  min-height: 500px;
}

.react-grid-Main{
  height: 100%;
  min-height: 500px;
}

.react-grid-Container{
  height: 100%;
  min-height: 500px;
}

.react-grid-Grid{
  height: 100%;
  min-height: 500px !important;
}

.react-grid-Canvas{
  height: 100%;
  min-height: 500px;
}

.react-grid-item {
  background-color: #1f86da;
}

.react-grid-layout {
  background: #d0d0d0;
}

.dragItemContainer {
  color: white;
}

.dragItem {
  font-size: 20px;
  height: 20px;
  width: 20px;
  margin: 0 auto;
  position: relative;
}

.react-resizable-handle {
  background-color: white;
}

.screenShotDialog {
  max-width: 100%;
  width : 800px;
}

.textPane ul {
  padding-left: 1.5em;
}

.textPane ol {
  padding-left: 1.5em;
}

.rdg-editor-container {
  z-index: 16777271 !important;
}

.card-input {
  border-style: solid;
  border-color: #8a8886;
  border-width: 1px;
  height: 26px;
  padding-top: 8px;
  padding-left: 4px;
}
