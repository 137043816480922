/**
 * The geosuggest module
 * NOTE: duplicated font-sizes' are for browsers which don't support rem (only IE 8)
 */
.geosuggest {
    position: relative;
    width: 100%;
    text-align: left;
}
.geosuggest__input {
    width: calc(100% - 8px);
    padding: .5em 0em .5em 0.5em;
}
.geosuggest__input:focus {
    border: 1px solid #0981c4;
    box-shadow: 0 0 0px #010101;
}
.geosuggest__suggests {
    width: calc(100%);
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 25em;
    padding: 0;
    margin-top: -1px;
    background: #fff;
    border: 1px solid #0981c4;
    border-top-width: 0;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;
    z-index: 5;
    -webkit-transition: max-height 0.2s, border 0.2s;
    transition: max-height 0.2s, border 0.2s;
}
.geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
}

/**
 * A geosuggest item
 */
.geosuggest__item {
    font-size: 14px;
    padding: .5em 0em .5em 0.5em;
    cursor: pointer;
}
.geosuggest__item:hover,
.geosuggest__item:focus {
    background: #f5f5f5;
}
.geosuggest__item--active {
    background: #0981c4;
    color: #fff;
}
.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
    background: #ccc;
}
.geosuggest__item__matched-text {
    font-weight: bold;
}
